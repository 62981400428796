/**
 * Main script
 */

import { debounce } from './utils';

window.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('gw-c-page__wrapper');

  if (wrapper) {
    wrapper.addEventListener('scroll', (event) => {
      function fippo(root) {
        const previousScroll = document.documentElement.dataset.scroll || 0;
        const currentScroll = root.scrollTop > 0 ? root.scrollTop : 0;
        const eventDetail = {
          scrollAmount: Math.abs(currentScroll - previousScroll),
        };

        if (currentScroll > previousScroll) {
          wrapper.dispatchEvent(new CustomEvent('scrolldown', {
            bubbles: true,
            detail: eventDetail,
          }));
        } else if (currentScroll < previousScroll) {
          wrapper.dispatchEvent(new CustomEvent('scrollup', {
            bubbles: true,
            detail: eventDetail,
          }));
        }
        document.documentElement.dataset.scroll = currentScroll;
      }
      return debounce(fippo)(event.target);
    });
  }

  const lotho = {
    initializers: {},
    destroyers: {},
  };

  lotho.start = function() {
    Object.entries(this.initializers).forEach(([key, init]) => init());
  };

  lotho.destroy = function() {
    Object.entries(this.destroyers).forEach(([key, destroy]) => destroy());
  };

  lotho.restart = function() {
    this.destroy();
    this.start();
  };

  window.LOTHO = lotho;

  window.dispatchEvent(new Event('lotho:init'));

});
