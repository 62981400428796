/**
 * Checks for the asset base url in the dataset of the html element or returns /.
 * @returns {string | string} The url path to assets.
 */
const assetsBaseUrl = () =>
  document.documentElement.dataset.assetsBaseUrl || '/';

/**
 * Executes callback method when DOM is ready.
 * @param callback Method to execute when DOM is ready.
 */
const domReady = callback => {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

/**
 * Requires all files in a given directory.
 * @param directory Directory to load files from.
 * @param useSubdirectories Whether to search sub-directories.
 * @param regExp Pattern to filter files loaded.
 */
const requireAll = requireContext => {
  requireContext.keys().forEach(requireContext);
};

/**
 * Debounce a function
 * @param fn Function to debounce
 */
const debounce = (fn) => {

  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {

    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {

      // Call our function and pass any params we received
      fn(...params);
    });

  }
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export { assetsBaseUrl, domReady, requireAll, debounce, sleep };
